
/**
 * Module definition and dependencies
 */
angular.module('App', [

  //Angular & 3rd party
  'ngTouch',
  'ngAnimate',
  'ngSanitize',
  'ngMessages',
  'ngFileUpload',
  'ui.router',
  'monospaced.qrcode',

  //Meanie
  'Api.Service',
  'Store.Service',
  'Log.Service',
  'Convert.Service',
  'Storage.Service',
  'Modal.Service',
  'Focus.Service',
  'KeyCodes.Service',
  'DuplicateRequestsFilter.Decorator',

  //Core modules
  'App.Config',
  'App.Templates',
  'App.Component',
  'App.Auth',
  'App.Error',

  //App modules
  'App.Portal',
  'App.Public',
  'App.Admin',
  'App.Home',
  'App.Page',
  'App.Kiosk',
  'App.Profile',
  'App.Account',
  'App.Coupon',
  'App.Event',
  'App.Booking',
  'App.Calendar',
  'App.Directory',
  'App.Subscription',
  'App.OAuth.Callback',

  //Shared components
  'Shared.Sort.Component',
  'Shared.Help.Component',
  'Shared.Cards.Component',
  'Shared.Alert.Component',
  'Shared.Plans.Component',
  'Shared.Notice.Component',
  'Shared.Avatar.Component',
  'Shared.Spinner.Component',
  'Shared.Premium.Component',
  'Shared.Dropdown.Component',
  'Shared.DobInput.Component',
  'Shared.Calendar.Component',
  'Shared.PieChart.Component',
  'Shared.AreaState.Component',
  'Shared.DateInput.Component',
  'Shared.TimeInput.Component',
  'Shared.FeeMinMax.Component',
  'Shared.IconLabel.Component',
  'Shared.ButtonBar.Component',
  'Shared.IconTrend.Component',
  'Shared.Pagination.Component',
  'Shared.TimePicker.Component',
  'Shared.IconStatus.Component',
  'Shared.PortalLogo.Component',
  'Shared.IconPicker.Component',
  'Shared.PortalSteps.Component',
  'Shared.PortalFooter.Component',
  'Shared.ProgressBar.Component',
  'Shared.SelectCheck.Component',
  'Shared.ActivityFee.Component',
  'Shared.MoreOptions.Component',
  'Shared.EmailPicker.Component',
  'Shared.FormControls.Component',
  'Shared.CommentsList.Component',
  'Shared.MailingLists.Component',
  'Shared.S3Background.Component',
  'Shared.AddressInput.Component',
  'Shared.MemberPicker.Component',
  'Shared.MemberSearch.Component',
  'Shared.WeekdayPicker.Component',
  'Shared.PageSelection.Component',
  'Shared.PasswordCheck.Component',
  'Shared.PrivacyToggles.Component',
  'Shared.CouponSessions.Component',
  'Shared.MemberFinances.Component',
  'Shared.MemberActivity.Component',
  'Shared.OverrideToggle.Component',
  'Shared.GenericOptions.Component',
  'Shared.MembershipInfo.Component',
  'Shared.ValidationError.Component',
  'Shared.MemberPermissions.Component',
  'Shared.PaymentFeeExample.Component',
  'Shared.SubscriptionStatus.Component',
  'Shared.SubscriptionSearch.Component',
  'Shared.PaymentFeeStructure.Component',
  'Shared.AccountCreditAlerts.Component',
  'Shared.BookingView.Details.Component',
  'Shared.BookingView.Members.Component',
  'Shared.BookingView.Current.Component',
  'Shared.MatchesMemberConstraint.Service',

  //Modal components
  'Shared.Modal.Ok.Component',
  'Shared.Modal.Confirm.Component',

  //Models
  'Shared.Tag.Model',
  'Shared.Club.Model',
  'Shared.User.Model',
  'Shared.Text.Model',
  'Shared.Mode.Model',
  'Shared.Plan.Model',
  'Shared.Door.Model',
  'Shared.Area.Model',
  'Shared.Page.Model',
  'Shared.Event.Model',
  'Shared.Alert.Model',
  'Shared.Guest.Model',
  'Shared.Email.Model',
  'Shared.Access.Model',
  'Shared.ApiKey.Model',
  'Shared.Avatar.Model',
  'Shared.Coupon.Model',
  'Shared.System.Model',
  'Shared.Payout.Model',
  'Shared.Circle.Model',
  'Shared.Member.Model',
  'Shared.Country.Model',
  'Shared.Contact.Model',
  'Shared.Country.Model',
  'Shared.Comment.Model',
  'Shared.Booking.Model',
  'Shared.Payment.Model',
  'Shared.Address.Model',
  'Shared.Counter.Model',
  'Shared.Discount.Model',
  'Shared.Activity.Model',
  'Shared.Feedback.Model',
  'Shared.AuditLog.Model',
  'Shared.EmailLog.Model',
  'Shared.Resource.Model',
  'Shared.TagOrder.Model',
  'Shared.TimeRange.Model',
  'Shared.AccessLog.Model',
  'Shared.CheckInLog.Model',
  'Shared.VisitorLog.Model',
  'Shared.CircleMember.Model',
  'Shared.Membership.Model',
  'Shared.Newsletter.Model',
  'Shared.CouponType.Model',
  'Shared.Integration.Model',
  'Shared.ActivityLog.Model',
  'Shared.Transaction.Model',
  'Shared.CustomField.Model',
  'Shared.WaitingList.Model',
  'Shared.MemberGroup.Model',
  'Shared.CheckInRule.Model',
  'Shared.ContactGroup.Model',
  'Shared.Subscription.Model',
  'Shared.EventCategory.Model',
  'Shared.AccountCredit.Model',
  'Shared.ResourceAssignee.Model',

  //Services
  'Shared.Pwa.Service',
  'Shared.Push.Service',
  'Shared.Page.Service',
  'Shared.Papa.Service',
  'Shared.Xero.Service',
  'Shared.Sync.Service',
  'Shared.Kiosk.Service',
  'Shared.Domain.Service',
  'Shared.Sentry.Service',
  'Shared.Stripe.Service',
  'Shared.Moment.Service',
  'Shared.Months.Service',
  'Shared.Notice.Service',
  'Shared.Modules.Service',
  'Shared.Request.Service',
  'Shared.Filters.Service',
  'Shared.History.Service',
  'Shared.Helpers.Service',
  'Shared.Emitter.Service',
  'Shared.KeyDown.Service',
  'Shared.Debounce.Service',
  'Shared.Confetti.Service',
  'Shared.Settings.Service',
  'Shared.Weekdays.Service',
  'Shared.Password.Service',
  'Shared.Intercom.Service',
  'Shared.OpenGraph.Service',
  'Shared.QrScanner.Service',
  'Shared.Mailchimp.Service',
  'Shared.Broadcast.Service',
  'Shared.Interface.Service',
  'Shared.GoogleMaps.Service',
  'Shared.Pagination.Service',
  'Shared.DateFormat.Service',
  'Shared.GlobalScope.Service',
  'Shared.WelcomeFlow.Service',
  'Shared.VisitorFlow.Service',
  'Shared.PaymentFlow.Service',
  'Shared.PaymentFees.Service',
  'Shared.VersionCheck.Service',
  'Shared.Integrations.Service',
  'Shared.PaymentOutcome.Service',
  'Shared.ScrollPosition.Service',
  'Shared.GoogleCalendar.Service',
  'Shared.CampaignMonitor.Service',
  'Shared.ReplacementTags.Service',
  'Shared.RegistrationFlow.Service',
  'Shared.PaymentLineItems.Service',

  //Constants
  'Shared.Colors.Constant',
  'Shared.TextTypes.Constant',
  'Shared.AreaStates.Constant',
  'Shared.OutputStates.Constant',
  'Shared.DoorStates.Constant',
  'Shared.DoorLockStates.Constant',
  'Shared.DoorPositionStates.Constant',
  'Shared.RegexChinese.Constant',
  'Shared.PaymentTypes.Constant',
  'Shared.ModuleDefaults.Constant',
  'Shared.GroupRuleTypes.Constant',
  'Shared.GroupRuleConditions.Constant',
  'Shared.MembershipConstraints.Constant',
  'Shared.NotificationThresholdTypes.Constant',

  //Options
  'Shared.YesNo.Options',
  'Shared.Roles.Options',
  'Shared.Genders.Options',
  'Shared.FeeTypes.Options',
  'Shared.TagTypes.Options',
  'Shared.TaxTypes.Options',
  'Shared.PageIcons.Options',
  'Shared.AreaTypes.Options',
  'Shared.Durations.Options',
  'Shared.ClubTypes.Options',
  'Shared.DateFormat.Options',
  'Shared.TimeFormat.Options',
  'Shared.AlertTypes.Options',
  'Shared.EventTypes.Options',
  'Shared.EventModes.Options',
  'Shared.FeeMethods.Options',
  'Shared.LimitUnits.Options',
  'Shared.ClubRegexes.Options',
  'Shared.DebitCredit.Options',
  'Shared.FeePolicies.Options',
  'Shared.ReportTypes.Options',
  'Shared.AllowedModes.Options',
  'Shared.XeroAccounts.Options',
  'Shared.AutoLightsOn.Options',
  'Shared.AutoLightsOff.Options',
  'Shared.DiscountTypes.Options',
  'Shared.AnchorPeriods.Options',
  'Shared.EmailStatuses.Options',
  'Shared.AutoDoorsOpen.Options',
  'Shared.ActivityTypes.Options',
  'Shared.AuditLogTypes.Options',
  'Shared.TagOrderTypes.Options',
  'Shared.EventRuleTypes.Options',
  'Shared.EventAudiences.Options',
  'Shared.AuditLogModels.Options',
  'Shared.AutoDoorsClose.Options',
  'Shared.PaymentMethods.Options',
  'Shared.CouponTypeUses.Options',
  'Shared.TaxNumberTypes.Options',
  'Shared.OAuthProviders.Options',
  'Shared.ResourceStatus.Options',
  'Shared.PaymentOptions.Options',
  'Shared.CouponTypeTypes.Options',
  'Shared.PaymentFeeCover.Options',
  'Shared.ApprovalOptions.Options',
  'Shared.TagValidityUnits.Options',
  'Shared.CustomFieldTypes.Options',
  'Shared.ChangeThresholds.Options',
  'Shared.TransactionTypes.Options',
  'Shared.DayOfRecurrences.Options',
  'Shared.IntegrationTypes.Options',
  'Shared.VaccinationStatus.Options',
  'Shared.LightControlModes.Options',
  'Shared.EmailTemplateTypes.Options',
  'Shared.FeeHandlingMethods.Options',
  'Shared.PhoneFormatOptions.Options',
  'Shared.TagValidityOptions.Options',
  'Shared.EventSeriesHandling.Options',
  'Shared.MembershipLimitations.Options',
  'Shared.MemberSelectionMethods.Options',
  'Shared.EventNotificationTypes.Options',
  'Shared.MembershipNotifications.Options',
  'Shared.MembershipConstraintLabels.Options',
  'Shared.EventNotificationDurations.Options',
  'Shared.AutoRenewalPaymentStrategies.Options',

  //Modals
  'Shared.Modal.Controller',
  'Shared.Email.Modal',
  'Shared.Import.Modal',
  'Shared.Invite.Modal',
  'Shared.Feedback.Modal',
  'Shared.PickDate.Modal',
  'Shared.PickTime.Modal',
  'Shared.ShowTerms.Modal',
  'Shared.EditAccess.Modal',
  'Shared.UploadFile.Modal',
  'Shared.ClubFrozen.Modal',
  'Shared.ContactClub.Modal',
  'Shared.AddToGroups.Modal',
  'Shared.EditDiscount.Modal',
  'Shared.EnterAddress.Modal',
  'Shared.EditCategory.Modal',
  'Shared.AgreeToTerms.Modal',
  'Shared.MigrationInfo.Modal',
  'Shared.ReAuthenticate.Modal',
  'Shared.EditCustomPage.Modal',
  'Shared.EditCustomField.Modal',
  'Shared.AddToCategories.Modal',
  'Shared.RemoveFromGroups.Modal',
  'Shared.ManageCategories.Modal',
  'Shared.EnableNotifications.Modal',
  'Shared.ConfirmUnsavedChanges.Modal',
  'Shared.EditDynamicGroupRules.Modal',
  'Shared.VerifyVaccinationStatus.Modal',

  //Cards
  'Shared.Tags.Card',
  'Shared.Image.Card',
  'Shared.Notes.Card',
  'Shared.Access.Card',
  'Shared.Avatar.Card',
  'Shared.Comments.Card',
  'Shared.Discounts.Card',
  'Shared.Categories.Card',
  'Shared.CustomFields.Card',
  'Shared.Notifications.Card',

  //Directives
  'Shared.Form.Directive',
  'Shared.Match.Directive',
  'Shared.Input.Directive',
  'Shared.OnLoad.Directive',
  'Shared.Partial.Directive',
  'Shared.ToFixed.Directive',
  'Shared.Tooltip.Directive',
  'Shared.OnEnter.Directive',
  'Shared.HasError.Directive',
  'Shared.AutoGrow.Directive',
  'Shared.Lowercase.Directive',
  'Shared.Percentage.Directive',
  'Shared.MemberExists.Directive',

  //Filters
  'Shared.Log.Filter',
  'Shared.Moment.Filter',
  'Shared.Label.Filter',
  'Shared.Count.Filter',
  'Shared.Text.Nl2br.Filter',
  'Shared.Text.UCFirst.Filter',
  'Shared.Text.CutOff.Filter',
  'Shared.Text.Markdown.Filter',
  'Shared.Text.MarkMatches.Filter',
  'Shared.Array.Join.Filter',
  'Shared.Array.Distribute.Filter',
  'Shared.Array.SpaceDelimited.Filter',
  'Shared.AgeCondition.Filter',
  'Shared.CurrencySymbol.Filter',
  'Shared.DoorState.Filter',
  'Shared.AreaState.Filter',
  'Shared.NumPages.Filter',
  'Shared.Month.Filter',
  'Shared.IfBlank.Filter',
  'Shared.Ordinal.Filter',
  'Shared.Time.Filter',
  'Shared.TimeAdverb.Filter',
  'Shared.Timezone.Filter',
  'Shared.DurationFromSeconds.Filter',
  'Shared.DurationFromMinutes.Filter',
  'Shared.DurationDays.Filter',
  'Shared.Percentage.Filter',
  'Shared.Absolute.Filter',
  'Shared.Currency.Filter',
  'Shared.Identifier.Filter',
  'Shared.Recurrence.Filter',
  'Shared.Tick.Filter',
  'Shared.Toggle.Filter',
  'Shared.Visibility.Filter',
  'Shared.Duration.Filter',
  'Shared.Filesize.Filter',
  'Shared.DiffNow.Filter',
  'Shared.Weekdays.Filter',
  'Shared.RestrictionLimit.Filter',
  'Shared.MembershipConstraint.Filter',
  'Shared.Text.Inflect.Filter',
]);
