
/**
 * Module definition and dependencies
 */
angular.module('Shared.TimePicker.Component', [])

/**
 * Time input component
 */
.component('timePicker', {
  templateUrl: 'shared/time-picker/time-picker.html',
  bindings: {
    time: '<',
    format: '<',
    onChange: '&',
    onConfirm: '&',
  },

  /**
   * Component controller
   */
  controller($focus, $window, $element, $timeout, $keyCodes, Settings) {

    //Helper vars
    let $hours, $minutes;

    /**
     * Helper to add zero padding
     */
    function padZeroes(input) {
      let num = Number(input);
      if (isNaN(num)) {
        return '00';
      }
      return (num < 10 ? '0' : '') + String(num);
    }

    /**
     * Prevent invalid input
     */
    function preventInvalidInput(event) {

      //Whitelisted characters
      const allowed = [
        $keyCodes.COMBINED,
      ];

      //Check if control or numeric characters (including whitelisted ones)
      if (!$keyCodes.isControl(event) && !$keyCodes.isNumeric(event, allowed)) {
        event.preventDefault();
      }
    }

    /**
     * Helper to select text
     */
    function selectText() {
      if (!$window.getSelection().toString()) {
        this.setSelectionRange(0, this.value.length);
      }
    }

    /**
     * Helper to advance to the minutes field
     */
    function advanceToMinutes() {
      if (this.value.length > 1) {
        $focus($minutes);
      }
    }

    /**
     * Initialisation
     */
    this.$onInit = function() {

      //Find hour/minute input elements
      let $inputs = $element.find('input');
      $hours = angular.element($inputs[0]);
      $minutes = angular.element($inputs[1]);

      //Attach event handlers
      //NOTE: Using beforeinput fails on Safari/iOS10
      $hours
        .on('click', selectText)
        .on('focus', selectText)
        .on('keypress', preventInvalidInput)
        .on('input', advanceToMinutes);
      $minutes
        .on('click', selectText)
        .on('focus', selectText)
        .on('keypress', preventInvalidInput);

      //Focus on the hours input
      $focus($hours);

      //Get time format
      const format = this.format || Settings.get('general.timeFormat');

      //Store format
      this.timeFormat = format;
      this.isTwelveHourFormat = format.match(/mma$/);
    };

    /**
     * Destroy
     */
    this.$onDestroy = function() {

      //Clear event handlers
      $hours
        .off('click', selectText)
        .off('focus', selectText)
        .off('keypress', preventInvalidInput)
        .off('input', advanceToMinutes);
      $minutes
        .off('click', selectText)
        .off('focus', selectText)
        .off('keypress', preventInvalidInput);
    };

    /**
     * Change handler
     */
    this.$onChanges = function() {
      this.checkIfAmOrPm();
      this.checkIfMidnightOrMidday();
      this.updateInputs();
    };

    /**
     * Confirm
     */
    this.confirm = function() {
      this.updateTime();
      this.onConfirm();
    };

    /**
     * Update time based on hour/minute values
     */
    this.updateTime = function() {

      //First adjust input if we're using 12 hour format and a 24 hour format
      //has been entered
      if (this.isTwelveHourFormat) {
        if (Number(this.hours) > 12) {
          this.hours -= 12;
          this.isAm = false;
        }
        else if (Number(this.hours) === 0 || Number(this.hours) === 24) {
          this.hours = 12;
          this.isAm = true;
        }
      }

      //Get hours and minutes from inputs
      let hours = Number(this.hours);
      let minutes = Number(this.minutes);
      let time;

      //Ensure we always have numerical values
      if (hours === '') {
        hours = 0;
      }
      if (minutes === '') {
        minutes = 0;
      }

      //For twelve hour format and PM, add 12 hours
      if (this.isTwelveHourFormat) {
        if ((!this.isAm && hours < 12) || (this.isAm && hours === 12)) {
          hours += 12;
        }
      }

      //Adjust time
      time = hours * 60 + minutes;

      //Determine new time and ensure stays before midnight
      if (time > 1440) {
        time = 1440;
      }

      //If hasn't changed, just update the model to ensure we have proper values
      if (time === this.time) {
        return this.updateInputs();
      }

      //Set in model and propagate change (which will trigger inputs update)
      this.time = time;
      if (this.onChange) {
        this.onChange({time});
      }
    };

    /**
     * Update hour/minute values from time
     */
    this.updateInputs = function() {

      //For empty time, clear model
      if (this.time === null || this.time === undefined) {
        this.hours = '';
        this.minutes = '';
        return;
      }

      //Determine input values
      let hours = Math.floor(this.time / 60);
      let minutes = this.time - (hours * 60);

      //Wrap in timeout
      $timeout(() => {

        //Set hours and minutes
        this.hours = String(hours);
        this.minutes = padZeroes(minutes);

        //Adjust for AM/PM time
        if (this.isTwelveHourFormat) {
          if (!this.isAm && hours > 12 || this.isAm && hours === 24) {
            this.hours = String(hours - 12);
          }
          else if (hours === 0) {
            this.hours = String(12);
          }
        }
      });
    };

    /**
     * Toggle am/pm
     */
    this.toggleAmPm = function() {

      //Change AM/PM
      this.isAm = !this.isAm;

      //Switched to AM
      if (this.isAm || this.time === 1440) {
        this.time -= 720;
      }

      //Switched to PM
      else {
        this.time += 720;
      }

      //Propagate changes
      if (this.onChange) {
        this.onChange({time: this.time});
      }
    };

    /**
     * Check if AM or PM
     */
    this.checkIfAmOrPm = function() {
      this.isAm = (this.time < 720 || this.time === 1440);
    };

    /**
     * Determine time label
     */
    this.checkIfMidnightOrMidday = function() {
      $timeout(() => {

        //Reset
        this.isMidnight = false;
        this.isMidday = false;

        //Only for twelve hour time format
        if (this.isTwelveHourFormat) {
          if (this.time === 0 || this.time === 1440) {
            this.isMidnight = true;
          }
          else if (this.time === 720) {
            this.isMidday = true;
          }
        }
      });
    };
  },
});
