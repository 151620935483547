
/**
 * Module definition and dependencies
 */
angular.module('Shared.VersionCheck.Service', [])

/**
 * Service definition
 */
.factory('VersionCheck', function(
  $window, $notice, $timeout, $log, $http, Config
) {

  //Get config
  const {app: {version, revision, versionCheckDelay}} = Config;
  const currentVersion = `${version}-${revision}`;

  /**
   * Version check class
   */
  class VersionCheck {

    /**
     * Start auto check
     */
    startAutoCheck() {
      this.interval = setInterval(() => {
        this.check();
      }, (versionCheckDelay || 120) * 1000);
    }

    /**
     * Stop auto check
     */
    stopAutoCheck() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }

    /**
     * Check for new version
     */
    check() {

      //Query version file
      $http
        .get('/version.json', {
          ignoreServerError: true,
          ignoreTimeoutError: true,
        })
        .then(response => {

          //Invalid response
          if (!response.data || typeof response.data === 'string') {
            return;
          }

          //Extract data
          const {version: newVersion} = response.data;

          //The same version or already showing a notice?
          if (newVersion === currentVersion || this.notice) {
            return;
          }

          //New version available
          $log.log(`Current version ${currentVersion}, new version available ${newVersion}`);
          this.updateAvailable();
        });
    }

    /**
     * Show notice to update
     */
    showNotice() {

      //Don't show if already showing one
      if (this.notice) {
        return;
      }

      //Show notice
      this.notice = $notice
        .show(`New version available, click here to reload`, {
          hideAutomatically: false,
          onClick: () => this.reload(),
        });
    }

    /**
     * Hide notice
     */
    hideNotice() {
      if (this.notice) {
        this.notice.hide();
        this.notice = null;
      }
    }

    /**
     * Set auto reload
     */
    setAutoReload(autoReload = true) {
      this.autoReload = autoReload;
    }

    /**
     * Set service worker registration
     */
    setServiceWorker(registration) {
      this.registration = registration;
    }

    /**
     * Update service worker
     */
    updateServiceWorker() {
      if (this.registration && this.registration.waiting) {
        this.registration.waiting.postMessage('skipWaiting');
      }
    }

    /**
     * Update available
     */
    updateAvailable() {

      //Auto reload after a short delay
      if (this.autoReload) {
        return this.reloadDelayed(10);
      }

      //Show notice otherwise
      this.showNotice();
    }

    /**
     * Reload page
     */
    reload() {

      //Update service worker
      this.updateServiceWorker();

      //Hide notice
      this.hideNotice();

      //Reload page
      setTimeout(() => {
        $window.location.reload();
      }, 250);
    }

    /**
     * Reload with delay
     */
    reloadDelayed(delay = 10) {

      //Show notice
      $notice.show('Reloading for new version...', {
        hideAutomatically: false,
        spinner: true,
      });

      //Reload
      $timeout(() => {
        this.reload();
      }, delay * 1000);
    }
  }

  //Return new instance
  return new VersionCheck();
});
